<template class=" ">
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    :dark="dark"
    :right="$vuetify.rtl"
    :src="require('@/assets/side-image-1.jpg')"
    :mini-variant.sync="mini"
    mini-variant-width="80"
    app
    width="260"
  >
    <template v-if="drawerImage" #img="props">
      <v-img :key="image" :gradient="gradient" v-bind="props" />
    </template>

    <div class="px-2">
      <default-drawer-header />

      <v-divider class="mx-3" />

      <!-- <default-account-settings /> -->

      <v-divider class="mx-3 mb-2" />

      <default-list :items="menuSubList" />
    </div>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { get, sync } from "vuex-pathify";

import { mapState, mapMutations } from "vuex";

export default {
  name: "DefaultDrawer",
  data: () => ({
    defaultSideImage: false,
    subDepartments: [],
    object: {},
    //menuSubList: [],
    menuItems: [
      // {
      //   title: "Home",
      //   icon: "mdi-food",
      //   to: "/"
      // },
      // {
      //   title: "Snacks",
      //   to: "/sub-department/4",
      //   image: require("@/assets/sub departments/snacks.jpg")
      // },
      // {
      //   title: "Kothu",
      //   to: "/sub-department/5",
      //   image: require("@/assets/sub departments/kothu.jpg")
      // },
      // {
      //   title: "Roti",
      //   to: "/sub-department/6",
      //   image: require("@/assets/sub departments/roti.jpg")
      // }
    ],
  }),
  components: {
    DefaultAccountSettings: () =>
      import(
        /* webpackChunkName: "default-account-settings" */
        "./widgets/AccountSettings"
      ),
    DefaultDrawerHeader: () =>
      import(
        /* webpackChunkName: "default-drawer-header" */
        "./widgets/DrawerHeader"
      ),
    DefaultList: () =>
      import(
        /* webpackChunkName: "default-list" */
        "./List"
      ),
  },

  computed: {
    ...mapState(["menuSubList"]),

    ...get("user", ["dark", "gradient", "image"]),
    ...get("app", ["items", "version"]),
    ...sync("app", ["drawer", "drawerImage", "mini"]),

    sideImage() {
      return (
        "https://filesstorage.azurewebsites.net/img/" +
        window.location.host +
        "-side-image.png"
      );
    },
  },
  created() {
    this.getDataFromApi();
    var home = {
      title: "Home",
      image:
        "https://png.pngtree.com/png-clipart/20190516/original/pngtree-vector-shop-icon-png-image_3762863.jpg",
      // icon: "mdi-store",
      to: "/home",
    };
    this.ADD_MENU_LIST(home);
  },
  methods: {
    ...mapMutations(["ADD_MENU_LIST"]),

    getDataFromApi() {
      this.$http.get("/Menus").then((response) => {
        this.subDepartments = response.data;
        // this.subDepartments = this.subDepartments.reverse();
        //console.log(this.subDepartments);

        if (this.subDepartments.length > 0) {
          this.setPaths();
        }
      });
    },
    setPaths() {
      for (var i = 0; i < this.subDepartments.length; i++) {
        {
          // console.log(this.subDepartments[i].subDepartmentName);
          // console.log(this.subDepartments[i].subDepartmentId);
          // console.log(this.subDepartments[i].image);
          // console.log(this.subDepartments[i].noOfProducts);
          if (this.subDepartments[i].noOfProducts > 0) {
            var menuObject = {};
            menuObject.title = this.subDepartments[i].subDepartmentName;
            if (this.subDepartments[i].image) {
              menuObject.image = this.$url + this.subDepartments[i].image;
            } else {
              menuObject.image = require("@/assets/no image.png");
            }

            menuObject.to =
              `/sub-department/` + this.subDepartments[i].subDepartmentId;

            this.ADD_MENU_LIST(menuObject);
          }
        }
      }
    },
  },
};
</script>
<style>
.v-navigation-drawer__content {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: auto;
}
.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}
</style>

<style lang="sass">
#default-drawer

  .v-list-item
    margin-bottom: 8px

  .v-list-item::before
    display: none
  .v-list-item::after
    display: block

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px
</style>

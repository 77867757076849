<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    :dark="dark"
    :right="$vuetify.rtl"
    :src="require('@/assets/side-image-1.jpg')"
    :mini-variant.sync="mini"
    mini-variant-width="65"
    app
  >
    <template v-if="drawerImage" #img="props">
      <v-img :key="image" :gradient="gradient" v-bind="props" />
    </template>

    <div class="px-2">
      <default-drawer-header />

      <v-divider class="mx-3" />

      <!-- <default-account-settings /> -->

      <v-divider class="mx-3 mb-2" />

      <default-list class="tabList" :items="tabs" />

      
    </div>
  </v-navigation-drawer>
</template>

<style>
.tabList {
  display: inline;
}
</style>
<script>
// Utilities
import { get, sync } from "vuex-pathify";

export default {
  name: "DefaultDrawer",
  data: () => ({
    defaultSideImage: false
  }),
  components: {
    DefaultAccountSettings: () =>
      import(
        /* webpackChunkName: "default-account-settings" */
        "./widgets/AccountSettings"
      ),
    DefaultDrawerHeader: () =>
      import(
        /* webpackChunkName: "default-drawer-header" */
        "./widgets/DrawerHeader Admin"
      ),
    DefaultList: () =>
      import(
        /* webpackChunkName: "default-list" */
        "./List Admin"
      )
  },

  computed: {
    ...get("user", ["dark", "gradient", "image"]),
    ...get("app", ["tabs","tabs.children", "version"]),
    ...sync("app", ["drawer", "drawerImage", "mini"]),
    sideImage() {
      return (
        "https://filesstorage.azurewebsites.net/img/" +
        window.location.host +
        "-side-image.png"
      );
    }
  }
};
</script>

<style>
.v-navigation-drawer__content {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: auto;
}
.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}
</style>
<style lang="sass">

#default-drawer
  .v-list-item

    margin-bottom: 8px


  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
</style>
